import { ReactNode } from 'react';
import {
  MarginBottomProps,
  MarginTopProps,
  MinHeightProps,
  PaddingProps,
} from 'styled-system';

import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { Box } from 'components/Box';
import { FilestackImage } from 'components/FilestackImage/FilestackImage';
import { gutterWidth } from 'theme/theme';

import { HeroBackgroundHolder, HeroBackgroundImageHolder } from './Hero.styled';

type StyleProps = Pick<PaddingProps, 'pt' | 'pb' | 'px' | 'py'> &
  MinHeightProps &
  Pick<MarginBottomProps, 'mb'> &
  Pick<MarginTopProps, 'mt'>;

type Props = {
  heroImage: ApiFilestackImageData | undefined;
  mobileHeroImage?: ApiFilestackImageData;
  children: ReactNode;
  withOverlay?: boolean;
  withLightOverlay?: boolean;
  imageHeight?: ApiFilestackImageData['height'];
  'data-qa-id'?: string;
  eagerLoadImage?: boolean;
} & StyleProps;

export function Hero({
  heroImage,
  mobileHeroImage,
  children,
  withOverlay,
  withLightOverlay,
  imageHeight = 1024,
  'data-qa-id': dataQaId,
  eagerLoadImage,
  px,
  py,
  pt,
  pb,
  minHeight,
  mb,
  mt,
}: Props) {
  return (
    <Box
      position="relative"
      display="flex"
      width={1}
      minHeight={minHeight || ['320px', null, null, '450px']}
      pt={pt}
      pb={pb}
      mb={mb}
      mt={mt}
      px={px || [gutterWidth / 2, null, null, gutterWidth]}
      py={py || [48, null, null, 96]}
      justifyContent="center"
      alignItems="center"
      color="white"
      data-qa-id={dataQaId}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        width={1}
        height="100%"
        overflow="hidden"
      >
        {(heroImage || mobileHeroImage) && (
          <HeroBackgroundHolder>
            <HeroBackgroundImageHolder>
              <FilestackImage
                data-qa-id="hero-image-image"
                width={1920}
                height={imageHeight}
                expandSize
                objectFit="cover"
                alt={getText('Hero image')}
                data={heroImage || mobileHeroImage || null}
                mobileData={mobileHeroImage || undefined}
                mobileAspectRatio={{ width: 414, height: 470 }}
                eagerLoad={eagerLoadImage}
              />
            </HeroBackgroundImageHolder>
          </HeroBackgroundHolder>
        )}

        {(withOverlay || withLightOverlay) && (
          <Box
            position="absolute"
            transform="translateZ(0)"
            top={0}
            left={0}
            width={1}
            height="100%"
            bg={`rgba(0,0,0,${withLightOverlay ? 0.2 : 0.4})`}
          />
        )}
      </Box>

      <Box
        position="relative"
        transform="rotateZ(0)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="100%"
      >
        {children}
      </Box>
    </Box>
  );
}
